import { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'lodash';
import {
	canFetchMachinesNow,
	fetchMachinesFromNetwork,
	getLastFetchTime,
	getQueryableMachineIds,
	MACHINE_FETCH_CONFIG,
	setLastFetchTime,
} from 'App/Activity/MachineUtils';
import { Machine, MachineError } from 'App/Activity/ResourceManagerContext';
import { useVisibility } from 'App/Activity/useVisibility';
import { useConfigurationContext } from 'Configuration/useConfigurationContext';
import { FeatureFlag } from 'Environment/FeatureFlag';
import { useFeatureCanary } from 'utils/useFeatureCanary';
import { useLoadableResourceContext } from 'Workspace/ResourceProvider';
import { useUserContext } from 'Workspace/UserContext';

interface MachineStatusState {
	loading: boolean;
	machineData: (Machine | MachineError)[];
}

export const useMachineStatus = (): MachineStatusState => {
	const isMachinePowerStateEnabled = useFeatureCanary(
		FeatureFlag.EnableMachinePowerState
	);
	const { workspaceConfiguration } = useConfigurationContext();
	const { hasLoggedIn } = useUserContext();
	const {
		value: { resources },
	} = useLoadableResourceContext();

	const [loading, setLoading] = useState(false);
	const [machines, setMachines] = useState<(Machine | MachineError)[]>([]);

	const queryableMachineIds = useMemo(
		() => getQueryableMachineIds(resources),
		[resources]
	);

	const isVisible = useVisibility();
	const machineListEndpoint = useMemo(
		() => workspaceConfiguration?.storeProxy?.machinesProxy?.listMachinesURL || '',
		[workspaceConfiguration]
	);

	const fetchMachines = useCallback(
		async (url: string, desktopIds: string[], forceNetworkCall = false) => {
			const lastFetchTime = getLastFetchTime();
			const shouldFetch =
				isVisible &&
				(forceNetworkCall ||
					canFetchMachinesNow(lastFetchTime, MACHINE_FETCH_CONFIG.pollingInterval));

			if (!shouldFetch) {
				return;
			}
			setLoading(true);
			try {
				setLastFetchTime(Date.now());
				const machineData = await fetchMachinesFromNetwork(url, desktopIds, hasLoggedIn);
				setMachines(machineData);
			} finally {
				setLoading(false);
			}
		},
		[isVisible, hasLoggedIn]
	);

	const throttledFetchMachines = useMemo(() => {
		return throttle(fetchMachines, MACHINE_FETCH_CONFIG.pollingInterval, {
			leading: true,
			trailing: false,
		});
	}, [fetchMachines]);

	useEffect(() => {
		if (
			!isMachinePowerStateEnabled ||
			!isVisible ||
			!queryableMachineIds?.length ||
			!machineListEndpoint
		) {
			return () => {};
		}

		const interval = setInterval(() => {
			throttledFetchMachines(machineListEndpoint, queryableMachineIds);
		}, MACHINE_FETCH_CONFIG.pollingInterval);

		return () => clearInterval(interval);
	}, [
		isMachinePowerStateEnabled,
		queryableMachineIds,
		machineListEndpoint,
		isVisible,
		throttledFetchMachines,
	]);

	useEffect(() => {
		const lastFetchTime = getLastFetchTime();
		if (
			isMachinePowerStateEnabled &&
			queryableMachineIds?.length > 0 &&
			machineListEndpoint &&
			canFetchMachinesNow(lastFetchTime, MACHINE_FETCH_CONFIG.pollingInterval)
		) {
			throttledFetchMachines(machineListEndpoint, queryableMachineIds, true);
		}
	}, [
		isMachinePowerStateEnabled,
		throttledFetchMachines,
		queryableMachineIds,
		machineListEndpoint,
	]);

	return { loading, machineData: machines };
};
