import { t } from '@citrite/translate';
import { notifyWarning } from '@citrite/web-ui-component';
import { compact, first, isEqual, last, lowerCase, sortBy, take, without } from 'lodash';
import { appsPagePath } from 'App/Apps';
import { ResourceType } from 'App/Tile/ResourceDetails';
import { Resource, subscriptionStatus } from 'Workspace/ResourceProvider/resourceTypes';

export enum ResourceFilter {
	Applications,
	Desktops,
	QuickAccess,
}

enum SpecialCategory {
	All = 'All',
	Others = 'Others',
}

export const tooltipDelay = {
	show: 1000,
	hide: 50,
};

export const numberOfRecentResources = 5;

const homeScreenHash = '#/home';

const isSubscribedOrMandatory = (resource: Resource) =>
	resource.subscriptionstatus === subscriptionStatus.subscribed || resource.mandatory;

export const sortedResources = (resources: Resource[]) => {
	return sortBy(resources, [
		resource => !isSubscribedOrMandatory(resource),
		resource => lowerCase(resource.name),
	]);
};

export const equalsIgnoreCase = (str1: string, str2: string) =>
	str1?.toLowerCase() === str2?.toLowerCase();

const filterApplicationResources = (
	resources: Resource[],
	category: string = t('Workspace:all'),
	subCategoryPath?: any[]
) => {
	const applicationResources = resources.filter(resource => !resource.isdesktop);
	if (category === t('Workspace:all')) {
		return applicationResources;
	} else {
		return applicationResources.filter(({ path }) => {
			if (!path) {
				return category === t('Workspace:others');
			}
			const splitResourcePath = compact(path.split('\\'));
			const prefixResourcePath = splitResourcePath.slice(0, subCategoryPath.length);
			const suffixResourcePath = splitResourcePath.slice(subCategoryPath.length);
			const resourceCategory = path && last(prefixResourcePath);
			if (
				subCategoryPath !== null &&
				!checkEquality(subCategoryPath, prefixResourcePath)
			) {
				return category === t('Workspace:others') && !resourceCategory;
			}
			return (
				(category === t('Workspace:others') && !resourceCategory) ||
				(equalsIgnoreCase(resourceCategory, category) && suffixResourcePath.length === 0)
			);
		});
	}
};

const filterDesktopResources = (resources: Resource[]) =>
	resources.filter(resource => resource.isdesktop);
/**
 * Here Favourites and Recent resources are considered to be quick access
 * resources and they appear in Quick Access section of home screen.
 * All the resources which are marked as subscribed or set as mandatory or
 * if they are recently accessed would be considered by this filter
 */
const filterQuickAccessResources = (resources: Resource[]) => {
	const favouriteResources = resources.filter(resource =>
		isSubscribedOrMandatory(resource)
	);
	const recentApplicationResources = take(
		sortBy(
			filterApplicationResources(
				resources.filter(resource => 'recentsposition' in resource)
			),
			'recentsposition'
		),
		numberOfRecentResources
	);
	const recentDesktopResources = take(
		sortBy(
			filterDesktopResources(resources.filter(resource => 'recentsposition' in resource)),
			'recentsposition'
		),
		numberOfRecentResources
	);
	return [
		...without(
			favouriteResources,
			...recentApplicationResources,
			...recentDesktopResources
		),
		...recentApplicationResources,
		...recentDesktopResources,
	];
};

export const filteredResources = (
	resources: Resource[],
	filter: ResourceFilter,
	category?: string,
	subCategoryPath?: any[]
) => {
	switch (filter) {
		case ResourceFilter.Applications:
			return filterApplicationResources(resources, category, subCategoryPath);
		case ResourceFilter.Desktops:
			return filterDesktopResources(resources);
		case ResourceFilter.QuickAccess:
			return filterQuickAccessResources(resources);
		default:
			return resources;
	}
};

export const getSubcategories = (
	resources: Resource[],
	category: string = t('Workspace:all'),
	subCategoryPath: any[] = null
) => {
	const applicationResources = resources.filter(resource => !resource.isdesktop);
	if (category === t('Workspace:all')) {
		return [];
	} else {
		return Array.from(
			new Set(
				compact(
					applicationResources.map(({ path }) => {
						if (!path) {
							return '';
						}
						const splitResourcePath = compact(path.split('\\'));
						const prefixResourcePath = splitResourcePath.slice(0, subCategoryPath.length);
						const suffixResourcePath = splitResourcePath.slice(subCategoryPath.length);
						const resourceCategory = path && last(prefixResourcePath);
						if (
							subCategoryPath !== null &&
							!checkEquality(subCategoryPath, prefixResourcePath)
						) {
							return '';
						}
						if (
							equalsIgnoreCase(resourceCategory, category) &&
							suffixResourcePath.length > 0
						) {
							return first(suffixResourcePath);
						} else {
							return '';
						}
					})
				)
			)
		);
	}
};

const checkEquality = (listA: string[], listB: string[]) =>
	listA &&
	listB &&
	listA.length === listB.length &&
	listA.every((element: any, index: any) => equalsIgnoreCase(element, listB[index]));

export const getCategories = (resources: Resource[]): string[] => {
	const categories = Array.from(
		resources.reduce((categoriesSet, resource) => {
			const category = resource.path && first(compact(resource.path.split('\\')));
			if (category) {
				categoriesSet.add(String(category));
			}
			return categoriesSet;
		}, new Set<string>())
	);
	categories.sort();
	if (categories.length) {
		return [t('Workspace:all'), ...categories, t('Workspace:others')];
	} else {
		return [];
	}
};

export const isHomeScreen = () => {
	return window.location.hash.startsWith(homeScreenHash);
};

export const getResourceSubText = (type: string) => {
	let subText = '';
	switch (type) {
		case ResourceType.WebApp:
			subText = t('Workspace:ftu_ui.web_app');
			break;
		case ResourceType.VirtualApp:
			subText = t('Workspace:ftu_ui.desktop_app');
			break;
		case ResourceType.Desktop:
			subText = t('Workspace:ftu_ui.desktop');
			break;
		default:
			break;
	}

	return subText;
};

//allow-unused-export
export const isPathEmpty = (path?: string): boolean => {
	const trimmedPath = path?.trim();
	return !trimmedPath || trimmedPath.endsWith(appsPagePath);
};

export const determinePathToNavigate = (
	pathFromURL: string,
	appsViewTab: string | undefined,
	pathFromConfig: string | undefined
): string => {
	if (isPathEmpty(pathFromURL)) {
		if (appsViewTab === 'category' && !isPathEmpty(pathFromConfig)) {
			return pathFromConfig!;
		} else if (appsViewTab === 'uncategorized') {
			return SpecialCategory.Others;
		} else {
			return SpecialCategory.All;
		}
	}
	return pathFromURL;
};

const isSpecialCategory = (categories: string[]): boolean => {
	return (
		(categories?.length === 1 &&
			equalsIgnoreCase(first(categories), SpecialCategory.All)) ||
		equalsIgnoreCase(first(categories), SpecialCategory.Others)
	);
};

const normalizeSpecialCategory = (categories: string[]): string[] =>
	equalsIgnoreCase(first(categories), SpecialCategory.All)
		? [SpecialCategory.All]
		: [SpecialCategory.Others];

const normalizeCategoriesPath = (categories: string[]): string[] => {
	const normalizedCategories = compact(categories);

	return normalizedCategories.length === 0 ? [SpecialCategory.All] : normalizedCategories;
};

const normalizePath = (path: string): string => compact(path.split('\\')).join('\\');

const validateCategoriesPath = (
	resources: Resource[],
	categories: string[]
): string[] | null => {
	const normalizedCategories = categories?.join('\\').toLowerCase();

	for (const { path } of resources) {
		const normalizedPath = normalizePath(path).toLowerCase();
		if (normalizedPath.startsWith(normalizedCategories)) {
			return normalizePath(path).substring(0, normalizedCategories.length).split('\\');
		}
	}

	return isSpecialCategory(categories) ? normalizeSpecialCategory(categories) : null;
};

const findValidCategoriesPath = (
	categories: string[],
	resources: Resource[]
): string[] => {
	const validatedCategories = [...categories];
	let validatedCategoriesPath = validateCategoriesPath(resources, validatedCategories);

	while (validatedCategories.length > 0 && !validatedCategoriesPath) {
		validatedCategories.pop();
		validatedCategoriesPath = validateCategoriesPath(resources, validatedCategories);
	}

	return normalizeCategoriesPath(validatedCategoriesPath);
};

const notifyIfPathChanged = (
	originalCategories: string[],
	validatedCategories: string[]
): void => {
	if (!isEqual(originalCategories, validatedCategories)) {
		notifyWarning(t('Workspace:invalid_apps_category_path'));
	}
};

export const validatedCategoriesToNavigate = (
	categories: string[],
	resources: Resource[]
): string[] => {
	if (isSpecialCategory(categories)) {
		return normalizeSpecialCategory(categories);
	}

	const validatedCategories = findValidCategoriesPath(categories, resources);
	notifyIfPathChanged(categories, validatedCategories);

	return validatedCategories;
};

export const translateCategoryForDisplay = (category: string) => {
	if (category === SpecialCategory.All) {
		return t('Workspace:all');
	}
	if (category === SpecialCategory.Others) {
		return t('Workspace:others');
	}
	return category;
};

export const resolveCategoryForPath = (category: string) => {
	if (category === t('Workspace:all')) {
		return SpecialCategory.All;
	}
	if (category === t('Workspace:others')) {
		return SpecialCategory.Others;
	}
	return category;
};
